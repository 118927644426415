#rotater {
    transition: all 0.3s ease;
}

.my_scroll_div {
    overflow-y: auto;
    max-height: 100px;
}

.my_scroll_divs {
    overflow-y: auto;
    max-height: 900px;
}

.modal-dialog-full-width {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    max-width: none !important;
}

.modal-content-full-width {
    height: auto;
    min-height: 100%;
    border-radius: 0 !important;
}

.modal-header-full-width {
    border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width {
    border-top: 1px solid #9ea2a2 !important;
}